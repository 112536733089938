import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers"

import { TUser } from "src/data/user/user"

type MTimePickerProps = {
  label?: string
  time: Date | null
  setTime: (t: Date | null) => void
  clockType: TUser["clock_type"]
  required?: boolean
}

export function MTimePicker({
  time,
  setTime,
  label,
  clockType,
  required,
}: MTimePickerProps) {
  return (
    // These internals are using a deprecated sub-dependency of an already
    // deprecated UI library; it should be updated to something better as soon
    // as reasonably possible.
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePicker
        label={label}
        value={time}
        onChange={setTime}
        orientation="portrait"
        ampm={clockType === "12"}
        InputProps={{ endAdornment: <DropdownIcon /> }}
        required={required}
      />
    </MuiPickersUtilsProvider>
  )
}

// TODO-GT-814: This is an obviously non-standard and shoddy implementation of
// an SVG; it should be removed asap, but probably not before we've ported our
// dropdowns to the new UI.
function DropdownIcon() {
  return (
    <svg
      className="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M7 10l5 5 5-5z"></path>
    </svg>
  )
}
