import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"

import { useTranslate } from "src/i18n/useTranslate"
import { TMButtonProps } from "src/ui/Button/MButton"

export interface DateFieldDropdownProps extends TMButtonProps {
  onClick: () => void
  required?: boolean
  label?: React.ReactNode
  displayValue: string
}

export function DateFieldDropdown({
  onClick,
  label,
  required,
  displayValue,
}: DateFieldDropdownProps) {
  const { t, langKeys } = useTranslate()

  const dropdownLabel = label ?? t(langKeys.date)

  return (
    <FormControl required={required} fullWidth>
      <InputLabel htmlFor="datefield-dropdown" required={required}>
        {dropdownLabel}
      </InputLabel>
      <Select
        label={dropdownLabel}
        open={false}
        onOpen={onClick}
        value={displayValue}
        onChange={() => {}}
        inputProps={{ name: "time", id: "datefield-dropdown" }}
        required={required}
        fullWidth
      >
        <MenuItem value={displayValue}>{displayValue}</MenuItem>
      </Select>
    </FormControl>
  )
}
