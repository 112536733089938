import { format } from "date-fns"

import { useTranslate } from "src/i18n/useTranslate"
import { DateFieldDropdown } from "src/ui/DateRangePicker/DateFieldDropdown"
import { getDatePickerTimeFormat } from "src/ui/DateRangePicker/datePickerLogic"
import { TDateRange } from "src/ui/DateRangePicker/DateRangePicker"
import {
  DateRangePickerContainer,
  DateRangePickerContainerProps,
} from "src/ui/DateRangePicker/DateRangePickerContainer"

export type DatePickerDropdownProps = {
  date: Date | null
  setDate: (date: Date | null) => void
  required?: boolean
  label?: React.ReactNode
  dateRangePickerProps?: Partial<DateRangePickerContainerProps>
}

/** A customized DateRangePicker that only allows selecting one single date. */
export function DatePickerDropdown({
  label,
  date,
  setDate,
  required,
  dateRangePickerProps,
}: DatePickerDropdownProps) {
  const { t, langKeys } = useTranslate()
  const dropdownLabel = label ?? t(langKeys.date)

  return (
    <DateRangePickerContainer
      {...dateRangePickerProps}
      startDate={date}
      endDate={date}
      onDateRangeChange={({ startDate }: TDateRange) => {
        setDate(startDate)
      }}
      defaultPresetKey="MONTH"
      hideActionbar
      behavior="date"
      renderButton={({ onClick, state }) => {
        return (
          <DateFieldDropdown
            label={dropdownLabel}
            onClick={onClick}
            displayValue={getDispalyValue(state.startDate)}
            required={required}
          />
        )
      }}
    ></DateRangePickerContainer>
  )
}

function getDispalyValue(date: Date | null) {
  const formatType = getDatePickerTimeFormat(date, new Date())
  const dateRangeStr = date ? `${format(date, formatType)}` : ``
  return dateRangeStr
}
